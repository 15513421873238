import { FC } from "react";
import Link from "next/link";

import ListItem from "apps/website/components/base/ListItem/ListItem";
import Text from "apps/website/components/base/Text/Text";
import { Size } from "apps/website/components/base/Text/Text.map";
import { ILink } from "apps/website/components/base/Button/Button.map";

export interface IFooterLink {
  link: ILink;
  size?: Size;
  className?: string;
}

const FooterLink: FC<IFooterLink> = ({ link, size = "default", className }) => (
  <ListItem component="FooterLink" className={`mb-4 ${className}`}><Text tag="span" size={size}><Link
    href={link.to}
    className="hover:opacity-70 transition-opacity duration-300">{ link.name }</Link></Text></ListItem>
);

export default FooterLink;
