"use client";

import { useEffect, useState } from "react";

import DefaultLayout from "apps/website/layouts/Default";

import ErrorPage from "../components/page/ErrorPage/ErrorPage";
import { IStoryBlokHeader } from "../components/page/api.types";
import { getDefaultLayoutData } from "../utils/content/layout";
import { SBHelper } from "../storyblok/client";
import { ILayoutData } from "../layouts/layouts.map";

interface I404PageStory {
  header: IStoryBlokHeader;
}

interface I404PageProps {
  story: I404PageStory;
}

const getPageProps = async (): Promise<I404PageProps> => {

  const data = Promise.all(
    [
      SBHelper.getDefaultHeader(),
    ],
  );
  const [ header ] = await data;

  const story: I404PageStory = {
    header,
  };

  return {
    story,
  };
};

export default function PageNotFound() {

  const [ story, setStory ] = useState<I404PageStory>();
  const [ layoutData, setLayoutData ] = useState<ILayoutData>();

  useEffect(() => {
    if (story) {
      setLayoutData(getDefaultLayoutData(story));
    }
  }, [ story ]);

  useEffect(() => {
    const getPagePropsOnLoad = async () => {
      await getPageProps().then(({ story: pageStory }) => setStory(pageStory));
    };

    getPagePropsOnLoad().catch((error) => {
      throw Error(error);
    });
  }, []);

  return (
    <>
      { layoutData && (

        <DefaultLayout
          {...layoutData}
          head={{
            ...layoutData.head,
            seo: {
              ...layoutData.head?.seo,
              title: "Page Not Found",
            },
            hideFromSearch: true,
          }}
        >
          <ErrorPage
            title={"Looks like we're a bit lost..."}
            body={"This page doesn't exist, maybe you want to go back to the start?"}
            ctas={[
              {
                id: "home",
                to: "/",
                name: "Back to home page",
              },
            ]}
          />
        </DefaultLayout>
      ) }
    </>
  );
}
