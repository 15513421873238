const MINUTE = 60;

/**
 * Revalidation time constants, in seconds
 */
export enum RevalidationTime {
  /** 15 Minutes */
  STANDARD = MINUTE * 15,
  /** 1 Minute */
  ASAP = 1,
}
