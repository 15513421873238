import {
  SbReactComponentsMap,
  StoryblokClient,
  apiPlugin,
  getStoryblokApi as getStoryblokApiDefault,
  storyblokInit,
} from "@storyblok/react";

import { PublicConfig } from "../config/public";

const storyblokApi: StoryblokClient | undefined = undefined;
let _components: SbReactComponentsMap | undefined;

export const AppStoryblokInit = (components?: SbReactComponentsMap): StoryblokClient => {
  _components = components;

  storyblokInit({
    accessToken: PublicConfig.STORYBLOK_API_TOKEN,
    // Only enable the bridge if storyblok env is draft
    bridge: PublicConfig.STORYBLOK_ENV === "draft",
    use: [ apiPlugin ],
    apiOptions: {
      cache: {
        type: "memory",
        clear: "auto",
      },
    },
    components,
  });

  return getStoryblokApiDefault();
};

export const getStoryblokApi = (): StoryblokClient => {
  if (storyblokApi !== undefined) return storyblokApi;
  return AppStoryblokInit(_components);
};
