import { PublicConfig } from "../config/public";

export const DEFAULT_RELATIONS = [
  "section_carousel_review_external.reviews",
  "section_carousel_review_trust_pilot.reviews",
  "section_carousel_article_cards.cards",
  "section_carousel_team_members.members",
  "section_cat_list.cats",
  "section_product_list.products",
  "product.collection",
  "product.associated_page",
  "section_image_accordion_list.image_accordion_list_feature",
  "section_trust_pilot_review_wall.reviews",
  "section_carousel_review_testimonials.reviews",
  "section_review_trustpilot_feature.reviews",
  "section_review_trustpilot_feature.review",
  "rich_text_inline_story_modal.story",
];

export const ARTICLE_RELATIONS = [
  "article.categories",
  "article.author",
  "section_articles_carousel_related_articles.cards",
];

export const PRODUCT_RELATIONS = [
  "product.collection",
];

export const FORM_RELATIONS = [
  "type_to_select.options",
  "rich_text_inline_story_modal.story",
];

export const sbParams = {
  version: PublicConfig.STORYBLOK_ENV as "published" | "draft",
};
