import {
  IStoryBlokAnnouncement,
  IStoryBlokAnnouncementBanner,
  IStoryBlokCookieBar,
  IStoryBlokFooter,
  IStoryBlokNewsletterSignUpModal,
  IStoryBlokSeo,
} from "apps/website/components/page/api.types";
import {
  IStoryBlokAnnouncementBannerProps,
} from "apps/website/components/storyblok/page/StoryBlokAnnouncementBanner";
import { IFooter } from "apps/website/components/page/Footer/Footer";
import {
  IHeaderLink,
  ILink,
} from "apps/website/components/base/Button/Button.map";
import {
  HEADER_LINKS_DEFAULT,
  HEADER_LINKS_BREEDER,
  IHeaderLinks,
} from "apps/website/components/page/Header/Header.map";

import { makePageTags } from "../website/head";
import {
  getStoryBlokFooterGroup,
  getStoryBlokFooterGroups,
  getStoryBlokFooterNewsletter,
} from "../storyblok/page";
import { getStoryBlokPageWrapperLinks } from "../storyblok/links";
import { renderStoryBlokRichText } from "../storyblok/text";
import { ImageMax, getStoryBlokImage } from "../storyblok/image";

export const getHeadData = (
  seo: IStoryBlokSeo | undefined,
  hideFromSearch: boolean | undefined,
  pageLastBuilt: string | undefined,
  fallbackTags: IStoryBlokSeo | undefined,
) => ({
  seo: seo ? makePageTags(seo) : undefined,
  hideFromSearch: hideFromSearch || false,
  lastBuilt: pageLastBuilt,
  fallbackTags: fallbackTags ? makePageTags(fallbackTags) : undefined,
});

export const getAnnouncementBannerData = (
  pageAnnouncements: IStoryBlokAnnouncement[],
  defaultAnnouncements: IStoryBlokAnnouncementBanner,
): IStoryBlokAnnouncementBannerProps => ({
  pageAnnouncements,
  defaultAnnouncements,
});

export const getFooterData = (
  footer: IStoryBlokFooter,
): IFooter => ({
  newsletterSignUp: getStoryBlokFooterNewsletter(footer.newsletter_sign_up[0]),
  primaryLinkGroup: getStoryBlokFooterGroup(footer.primary_link_group[0]),
  secondaryLinkGroups: getStoryBlokFooterGroups(footer.secondary_link_groups),
  tertiaryLinks: getStoryBlokPageWrapperLinks(footer.tertiary_links).filter(
    (x) => !!x,
  ).map((y) => y as ILink),
});

export const getCookieBannerData = (
  banner: IStoryBlokCookieBar,
) => ({
  body: renderStoryBlokRichText(banner.body),
  cta: { id: banner.cta[0]._uid, ...banner.cta[0] },
});

export const getNewsletterData = (
  newsletter: IStoryBlokNewsletterSignUpModal,
) => ({
  title: newsletter.title,
  completeTitle: newsletter.complete_title,
  body: newsletter.body,
  completeBody: newsletter.complete_body,
  buttonText: newsletter.button_text,
  theme: newsletter.theme,
  image: getStoryBlokImage(newsletter.image, true, ImageMax.CONTAINER_SM),
});

const mapLinksWithAssociatedDiscount = (links: IHeaderLink[], discountCode: string) => links.map((link) => ({ ...link, to: link.withAssociatedDiscount ? `${link.to}?discount_code=${discountCode}` : link.to }));

const getHeaderLinksWithAssociatedDiscount = (headerLinks: IHeaderLinks, discountCode: string | undefined) => {
  if (!discountCode) return headerLinks;
  return {
    primaryLinks: mapLinksWithAssociatedDiscount(headerLinks.primaryLinks, discountCode),
    secondaryLinks: mapLinksWithAssociatedDiscount(headerLinks.secondaryLinks, discountCode),
    authLinks: mapLinksWithAssociatedDiscount(headerLinks.authLinks, discountCode),
    guestLinks: mapLinksWithAssociatedDiscount(headerLinks.guestLinks, discountCode),
  };
};

// @ts-ignore
export const getDefaultLayoutData = (story?: any, lastBuilt?: string, scripts?: HeaderScript[] = [ "trustpilot" ]): IPageLayout => { // eslint-disable-line

  const associatedDiscount = story?.main?.content?.associated_discount_code;

  return {
    headerLinks: story?.main?.content?.header_navigation === "breeder" ? getHeaderLinksWithAssociatedDiscount(HEADER_LINKS_BREEDER, associatedDiscount) : getHeaderLinksWithAssociatedDiscount(HEADER_LINKS_DEFAULT, associatedDiscount),
    head: story?.main && getHeadData(
      story.main.content.seo,
      story.main.content.hide_from_search_engines,
      lastBuilt,
      story.fallbackTags,
    ),
    scripts,
    announcementBanner: (
      story?.main?.content?.announcements || story?.announcementBanner
    ) && getAnnouncementBannerData(story.main?.content?.announcements, story.announcementBanner),
    footer: story?.footer && getFooterData(story.footer),
    cookieBanner: story?.cookieBar && getCookieBannerData(story.cookieBar),
    hideCookieBanner: story?.main?.content?.hide_cookie_bar,
    hideAnnouncementBanner: story?.main?.content?.hide_announcements || false,
    newsletterModal: story?.newsletterSignUpModal && getNewsletterData(story.newsletterSignUpModal),
    lastBuilt,
  };
};
