import { ReactNode } from "react";

import { IFooter } from "../components/page/Footer/Footer";
import {
  ISectionNewsletterSignUpModal,
} from "../components/section/SectionNewsletterSignModal/SectionNewsletterSignModal";
import { ICookieBar } from "../components/page/CookieBar/CookieBar";
import { IHeaderLinks } from "../components/page/Header/Header.map";
import {
  IStoryBlokAnnouncementBannerProps,
} from "../components/storyblok/page/StoryBlokAnnouncementBanner";
import { IStoryBlokHead } from "../components/page/api.types";

export const HEADER_SCRIPTS: Record<string, string> = {
  trustpilot: "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js",
};

export type HeaderScript = keyof typeof HEADER_SCRIPTS;
export interface ILayout {
  children: ReactNode;
  head?: IStoryBlokHead;
  lastBuilt?: string;
  schemaScripts?: ISchemaScript[];
  scripts?: HeaderScript[];
  hideAnnouncementBanner?: boolean;
  associatedDiscount?: string;
  announcementBanner?: IStoryBlokAnnouncementBannerProps;
  footer?: IFooter;
  newsletterModal?: ISectionNewsletterSignUpModal;
  hideCookieBanner?: boolean;
  cookieBanner?: ICookieBar;
  headerLinks: IHeaderLinks;
}

export type ILayoutData = Omit<ILayout, "children">;

export interface ISchemaScript {
  id: string;
  data: Record<string, string | string[] | null>
}
