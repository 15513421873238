import { FC } from "react";

import {
  BreakpointListColumns,
  ListColumns,
} from "apps/website/components/base/List/List.map";
import Accordion from "apps/website/components/feature/Accordion/Accordion";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Column from "apps/website/components/layout/Column/Column";
import Grid from "apps/website/components/layout/Grid/Grid";
import List from "apps/website/components//base/List/List";
import FooterLink from
  "apps/website/components/page/Footer/FooterLink/FooterLink";

export interface IFooterLinkGroupProps {
  id: string;
  title: string;
  links: ILink[];
}
export interface IFooterGroup {
  group: IFooterLinkGroupProps;
  id: string;
  label: string;
  columns?: ListColumns | BreakpointListColumns;
}

const FooterLinkGroup: FC<IFooterGroup> = ({ group, id, label, columns = "default" }) => {

  const AccordionContent = () => (
    <nav id={id} aria-label={label}>
      <List columns={columns}>
        { group.links.map((link) => <FooterLink key={link.id} link={link} />) }
      </List>
    </nav>
  );

  return (
    <Grid component="FooterLinkGroup" theme="footer">
      <Column>
        <Accordion
          title={group.title}
          state={{ lg: "open" }}
          Content={<AccordionContent />}
          type="footer"
          theme="footer"
        />
      </Column>
    </Grid>
  );
};

export default FooterLinkGroup;
