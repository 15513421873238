// export const getStoryBlokHeaderGroups = () => {};

import {
  IStoryBlokFooterLinkGroup,
  IStoryBlokNewsletterSignUp,
} from "apps/website/components/page/api.types";
import {
  IFooterLinkGroupProps,
} from "apps/website/components/page/Footer/FooterLinkGroup/FooterLinkGroup";

import { getStoryBlokCtas } from "./links";

export const getStoryBlokFooterNewsletter = (newsletter: IStoryBlokNewsletterSignUp) => ({
  title: newsletter.title,
  completeTitle: newsletter.complete_title,
  body: newsletter.body,
  completeBody: newsletter.complete_body,
  buttonText: newsletter.button_text,
});

export const getStoryBlokFooterGroup = (group: IStoryBlokFooterLinkGroup): IFooterLinkGroupProps => ({
  id: group._uid,
  title: group.title,
  links: getStoryBlokCtas(group.links),
});

export const getStoryBlokFooterGroups = (groups: IStoryBlokFooterLinkGroup[]): IFooterLinkGroupProps[] => (
  groups.map((group) => getStoryBlokFooterGroup(group))
);
