import { FC } from "react";

import { themeRootClassMap } from "apps/website/maps/Theme.map";
import Column from "apps/website/components/layout/Column/Column";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import List from "apps/website/components/base/List/List";
import Logo from "apps/website/components/base/Icon/Logo/Logo";
import Text from "apps/website/components/base/Text/Text";
import ListItem from "apps/website/components/base/ListItem/ListItem";
import { ILink } from "apps/website/components/base/Button/Button.map";
import NewsletterSignUp, {
  INewsletterSignUp,
} from "apps/website/components/feature/NewsletterSignUp/NewsletterSignUp";

import FooterLinkGroup, {
  IFooterLinkGroupProps,
} from "./FooterLinkGroup/FooterLinkGroup";
import FooterLink from "./FooterLink/FooterLink";

export interface IFooter {
  className?: string;
  newsletterSignUp: INewsletterSignUp;
  primaryLinkGroup: IFooterLinkGroupProps,
  secondaryLinkGroups: IFooterLinkGroupProps[],
  tertiaryLinks: ILink[],
}

const Footer: FC<IFooter> = ({
  className,
  primaryLinkGroup,
  secondaryLinkGroups,
  tertiaryLinks,
  newsletterSignUp,
}) => {

  const computedClasses = themeRootClassMap.footer;

  const [ secondaryLinkGroupList1, secondaryLinkGroupList2, ...secondaryLinkGroupListSecondary ] = secondaryLinkGroups;
  const secondaryLinkGroupListPrimary = [ secondaryLinkGroupList1, secondaryLinkGroupList2 ];

  return (
    <footer data-component="Footer" data-theme="footer" className={`${computedClasses} ${className}`}>
      <Spacer size="xl" />
      <Container>
        <Grid>
          <Column spans={{ lg: 1 }} className="hidden lg:block">&nbsp;</Column>
          <Column spans={{ lg: 5 }} className="flex">
            <NewsletterSignUp
              title={newsletterSignUp.title}
              completeTitle={newsletterSignUp.completeTitle}
              body={newsletterSignUp.body}
              completeBody={newsletterSignUp.completeBody}
              buttonText={newsletterSignUp.buttonText}
              hideLabels={true}
              source="Footer"
            />
            <Spacer size="xl" />
          </Column>
          <Column spans={{ lg: 1 }} className="hidden lg:block">&nbsp;</Column>
          <Column spans={{ lg: 4 }}>
            <Grid gap="none">
              <>
                <Column className="lg:hidden ">
                  <FooterLinkGroup group={primaryLinkGroup} id="primary-footer-navigation" label="Primary footer navigation" columns={{ lg: 2 }} />
                </Column>
                { secondaryLinkGroupListPrimary.map((group) => (
                  <Column key={group.id} spans={{ lg: 6 }}>
                    <FooterLinkGroup group={group} id={`secondary-footer-navigation-${group.id}`} label={`${group.title} footer navigation`} />
                  </Column>
                )) }
              </>
            </Grid>
          </Column>
          <Column spans={{ lg: 1 }} className="hidden lg:block">&nbsp;</Column>
        </Grid>
        <Grid gap="none">
          <Column spans={{ lg: 1 }} className="hidden lg:block">&nbsp;</Column>
          <Column spans={{ lg: 5 }}>
            <FooterLinkGroup
              group={primaryLinkGroup}
              id="primary-footer-navigation"
              label="Primary footer navigation" columns={{ lg: 2 }}
            />
          </Column>
          <Column spans={{ lg: 1 }} className="hidden lg:block">&nbsp;</Column>
          <Column spans={{ lg: 4 }}>
            <Grid gap="none">
              { secondaryLinkGroupListSecondary.map((group) => (
                <Column key={group.id} spans={{ lg: 6 }}>
                  <FooterLinkGroup group={group} id={`secondary-footer-navigation-${group.id}`} label={`${group.title} footer navigation`} />
                </Column>
              )) }
            </Grid>
          </Column>
          <Column spans={{ lg: 1 }} className="hidden lg:block">&nbsp;</Column>
        </Grid>
      </Container>
      <Spacer size="xl" />
      <Grid>
        <Column direction="row" justify="center">
          <Text tag="div" className="w-full">
            <Logo />
          </Text>
        </Column>
      </Grid>
      <Container>
        <nav id="tertiary-footer-navigation" aria-label="Tertiary footer navigation">
          <List direction="row" justify="center" align="center">
            <ListItem className="mb-4 mx-4">
              <Text tag="span" size="xs">
                &copy;KatKin
                { " " }
                { new Date().getFullYear() }
              </Text>
            </ListItem>
            { tertiaryLinks.map((link) => <FooterLink key={link.id} link={link} size="xs" className="mx-4" />) }
          </List>
        </nav>
      </Container>
      <Spacer size="4xl" />
    </footer>
  );
};

export default Footer;
