import { Metadata } from "next";

import { IStoryBlokSeo } from "apps/website/components/page/api.types";
import {
  DEFAULT_PAGE_TITLE,
  PAGE_TITLE_SUFFIX,
} from "apps/website/constants/website";
import { getStoryBlokImage } from "apps/website/utils/storyblok/image";
import { IImageProps } from "apps/website/components/base/Image/Image";

export interface ISeo {
  title: string;
  description?: string;
  og_title?: string;
  og_description?: string;
  og_image?: IImageProps;
  twitter_title?: string;
  twitter_description?: string;
  twitter_image?: IImageProps;
}

export interface IHead {
  seo?: ISeo;
  hideFromSearch?: boolean;
  fallbackTags?: ISeo;
  lastBuilt?: string;
}

export const makePageTitle = (seo: ISeo) => (seo?.title ? `${seo.title} ${PAGE_TITLE_SUFFIX}` : DEFAULT_PAGE_TITLE);

export const makePageTags = (tags?: IStoryBlokSeo): ISeo => ({
  title: tags?.title || "KatKin",
  description: tags?.description,
  og_title: tags?.og_title,
  og_description: tags?.og_description,
  og_image: tags?.og_image && getStoryBlokImage(tags?.og_image),
  twitter_title: tags?.twitter_title,
  twitter_description: tags?.twitter_description,
  twitter_image: tags?.twitter_image && getStoryBlokImage(tags?.twitter_image),
});

export const
  makeDefaultHead = (headData: IHead): Metadata => ({
    title: headData.seo?.title || undefined,
    openGraph: {
      title: headData.fallbackTags?.og_title || undefined,
      description: headData.fallbackTags?.og_description || undefined,
      images: headData.fallbackTags?.og_image?.src ? [
        headData.fallbackTags?.og_image.src,
      ] : undefined,
    },
    twitter: {
      title: headData.fallbackTags?.twitter_title || undefined,
      description: headData.fallbackTags?.twitter_description || undefined,
      images: headData.fallbackTags?.twitter_image?.src ? [
        headData.fallbackTags?.twitter_image.src,
      ] : undefined,
    },
    other: {
      lastBuilt: `${headData.lastBuilt}`,
    },
    robots: headData.hideFromSearch ? "noindex" : undefined,
  });
