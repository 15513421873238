import { FC, ReactNode, memo } from "react";

export interface IMain {
  className?: string;
  children: ReactNode
}

const Main: FC<IMain> = ({ className, children }) => (
  <main data-component="Main" id="main" className={`animate--fade-in ${className}`}>
    { children }
  </main>
);

export default memo(Main);
